import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ImageService} from 'src/app/services/image.service';
import {Subscription} from 'rxjs';
import {Slide} from 'src/app/models/slide.model';
import {DeviceDetectorService} from 'ngx-device-detector';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

    constructor(
        public imageService: ImageService,
        private deviceService: DeviceDetectorService
    ) {
    }

    slides: Slide[] = [];
    currentPage = 0;
    @ViewChild('prev') prev: ElementRef;
    @ViewChild('current') current: ElementRef;
    @ViewChild('next') next: ElementRef;
    @ViewChild('nextfar') nextfar: ElementRef;

    private slidesSub: Subscription;

    timeLeft = 4;
    interval;

    public disabled = false;

    nextSlide() {
        if (this.deviceService.browser !== 'Safari') {
            this.prev.nativeElement.classList.remove('x-prev');
            this.prev.nativeElement.classList.add('x-prev-far');

            this.current.nativeElement.classList.remove('x-current');
            this.current.nativeElement.classList.add('x-prev');

            this.next.nativeElement.classList.remove('x-next');
            this.next.nativeElement.classList.add('x-current');

            this.nextfar.nativeElement.classList.remove('x-next-far');
            this.nextfar.nativeElement.classList.add('x-next');
        }

        this.currentPage++;

        setTimeout(() => {
            this.clearSlides();
        }, 400);

    }

    clearSlides() {
        // console.log('clear');

        if (this.deviceService.browser !== 'Safari') {
            this.prev.nativeElement.classList.remove('x-prev-far');
            this.prev.nativeElement.classList.add('y-prev');

            this.current.nativeElement.classList.add('y-current');
            this.current.nativeElement.classList.remove('x-prev');

            this.next.nativeElement.classList.remove('x-current');
            this.next.nativeElement.classList.add('y-next');

            this.nextfar.nativeElement.classList.remove('x-next');
            this.nextfar.nativeElement.classList.add('y-next-far');
        }
        const temp: Slide = this.slides[0];
        this.slides.shift();
        this.slides.push(temp);

        setTimeout(() => {
            this.normalizeSlides();
        }, 500);
    }

    normalizeSlides() {
        // console.log('NORMALIZE');

        if (this.deviceService.browser !== 'Safari') {
            this.prev.nativeElement.classList.remove('y-prev');
            this.prev.nativeElement.classList.add('x-prev');

            this.current.nativeElement.classList.remove('y-current');
            this.current.nativeElement.classList.add('x-current');

            this.next.nativeElement.classList.remove('y-next');
            this.next.nativeElement.classList.add('x-next');

            this.nextfar.nativeElement.classList.remove('y-next-far');
            this.nextfar.nativeElement.classList.add('x-next-far');
        }
    }


    ngOnInit(): void {
        this.imageService.getSlides();
        this.slidesSub = this.imageService
            .getSlideUpdateListener()
            .subscribe((slidesData: { slides: Slide[] }) => {
                this.slides = slidesData.slides;
                // console.log(this.slides);
            });
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.nextSlide();
                this.timeLeft = 4;
            }
        }, 1000);
    }

    ngOnDestroy() {
        this.slidesSub.unsubscribe();
    }
}
