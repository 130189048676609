import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Article} from '../models/article.model';
import {environment} from '../../environments/environment';

@Injectable()
export class ArticleService {
    private articles: Article[] = [];
    private articlesUpdated = new Subject<{ articles: Article[] }>();
    lastRouteBS = new BehaviorSubject('index');

    constructor(private http: HttpClient, private router: Router) {
    }

    getArticles(category: number) {
        const queryParams = `?category=${category}`;
        this.http
            .get<{ message: string; articles: any }>(
                environment.backendUrl + 'articles/' + queryParams
            )
            .pipe(
                map(articleData => {
                    // console.log(articleData.message);
                    return {
                        articles: articleData.articles.map(article => {
                            return {
                                id_: article._id,
                                id: article.id,
                                title: article.title,
                                content: article.content,
                                category: article.category,
                                state: article.state,
                                date: article.date
                            };
                        })
                    };
                })
            )
            .subscribe(transformedArticleData => {
                this.articles = transformedArticleData.articles;
                this.articlesUpdated.next({
                    articles: [...this.articles]
                });
            });
    }

    getAllArticles(): Observable<Article[]> {
        return this.http.get<Article[]>(environment.backendUrl + 'articles/all');
    }

    getArticleUpdateListener() {
        return this.articlesUpdated.asObservable();
    }

    getArticle(id: string) {
        const queryParams = `?id=${id}`;
        return this.http.get<Article>(environment.backendUrl + 'article' + queryParams);
    }

    addArticle(article: Article): Observable<Article> {
        return this.http.post<Article>(environment.backendUrl + 'article', article);
    }

    updateArticle(article: Article): Observable<Article> {
        return this.http.put<Article>(environment.backendUrl + 'article', article);
    }

    deleteArticle(id: string) {
        const queryParams = `?_id=${id}`;
        return this.http.delete(environment.backendUrl + 'article' + queryParams);
    }

    changeLastRoute(message: string): void {
        this.lastRouteBS.next(message);
    }

    uploadImage(file: any) {
        const formData = new FormData();
        formData.set('image', file, file.name);
        return this.http.post(environment.backendUrl + 'article/image', formData);
    }
}
