<div id="about" class="section">
    <div class="container">
        <div class="about-text">
            <h1 class="section-title">Rólunk</h1>
            <p class="section-description">Vállalkozásunk 1986 óta van jelen a magyar építőiparban. Tervezési és
                kivitelezési munkák mellett, műszaki ellenőrzési és gépi földmunkák elvégzését is vállaljuk. Amennyiben
                többet szeretne megtudni vállalkozásunkról, kattintson a Rólunk menüpontra. </p>
        </div>
        <div class="about-content">
            <div class="about-person">
                <img class="about-person-image" src="./assets/images/photos/Csilla.jpg">
            </div>
            <div class="about-description">
                <p class="about-name">Bartók Csilla Zsófia</p>
                <p class="about-company">Oros-Ház Kft.</p>
                <button class="standard-button" [routerLink]="['/article/rolunk']">Többet rólunk</button>
            </div>
        </div>
    </div>
</div>
