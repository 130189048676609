<div id="expertise" class="section">
    <div class="container">
        <div class="expertise-text">
            <h1 class="section-title">Szakterületek</h1>
            <p class="section-description">Vállaljuk családi és társasházak, ipari, mezőgazdasági és középületek,
                turisztikai létesítmények teljes körű tervezését, műszaki ellenőrzését, felújítását és kivitelezését,
                illetve a már meglévő épületek energetikai tanúsítványának kiállítását. Az elmúlt évtizedek munkái kellő
                tapasztalatot és szakértelmet biztosítanak számunkra, miközben jól kiépült és megbízható beszállítói
                hálózattal rendelkezünk, legyen szó épületvillamossági és gépészeti, tűzvédelmi, környezetvédelmi vagy
                mélyépítési szakmunkákról. Célunk nemcsak minőségi, modern megoldások alkalmazása, de munkánk során
                törekszünk a megrendelő kívánságainak és elképzeléseinek teljes körű megvalósítására is. Tervezést, az
                évek óta hatékonyan működő, innovatív üzleti modellünknek köszönhetően ország egész területén, illetve
                külföldön is vállalunk. Kivitelezéssel elsősorban Orosházán és környékén foglalkozunk.<br><br></p>
            <div class="expertise-button-container">
                <a class="standard-button" href="#contact">Kapcsolatfelvétel</a>
            </div>
        </div>
        <div class="construction-image-container">
            <img class="construction-image" src="./assets/images/artwork/construction.png">
        </div>

        <div class="expertise-mobile-container">
            <div class="expertise-categories-list">
                <div #category0 class="expertise-category expertise-category-active" (click)="selectCategory(0)"
                     [ngStyle]="{'color': (selected === 0) ? '#2870EF' : '#747474'}">
                    <img class="expertise-categories-icon expertise-category-active"
                         src="./assets/images/icons/expertise/tervezes.png"
                         [ngStyle]="{'filter': (selected === 0) ? 'grayscale(0)' : 'grayscale(1)'}">Tervezés
                </div>
                <div #category2 class="expertise-category" (click)="selectCategory(2)"
                     [ngStyle]="{'color': (selected === 2) ? '#2870EF' : '#747474'}">
                    <img class="expertise-categories-icon" src="./assets/images/icons/expertise/ellenorzes.png"
                         [ngStyle]="{'filter': (selected === 2) ? 'grayscale(0)' : 'grayscale(1)'}">Műszaki ellenőrzés
                </div>
                <div #category3 class="expertise-category" (click)="selectCategory(3)"
                     [ngStyle]="{'color': (selected === 3) ? '#2870EF' : '#747474'}">
                    <img class="expertise-categories-icon" src="./assets/images/icons/expertise/tanusitvany.png"
                         [ngStyle]="{'filter': (selected === 3) ? 'grayscale(0)' : 'grayscale(1)'}">Energetikai
                    tanúsítvány
                </div>
                <div #category4 class="expertise-category" (click)="selectCategory(4)"
                     [ngStyle]="{'color': (selected === 4) ? '#2870EF' : '#747474'}">
                    <img class="expertise-categories-icon" src="./assets/images/icons/expertise/foldmunkak.png"
                         [ngStyle]="{'filter': (selected === 4) ? 'grayscale(0)' : 'grayscale(1)'}">Földmunkák
                </div>
            </div>
            <div id="underline-parent" #underline_parent>
                <div id="underline" #underline [ngStyle]="{'width': lineWidth + 'px', 'left': lineOffset + 'px'}"></div>
            </div>
        </div>
    </div>
</div>
