<div id="blog-category" class="container">
    <h3 id="placeholder-category-title" class="blog-category-title">{{ categoryTitle }}</h3>
    <p id="placeholder-category-description" class="blog-category-description" [innerHTML]="categoryDescription"></p>
    <div class=blog-category-top-bar>
        <div id="blog-category-all" class="no-touch blog-category-all-icon-hoverable" (click)="toggleExpandArticles()" [ngStyle]="{'opacity': articles.length > 4 ? 1 : 0 }">{{ articlesExtended == 4 ? "Összes megjelenítése" : "Kevesebb megjelenítése" }}<img
                id="blog-category-all-icon" src="./assets/images/icons/right-arrow.png"></div>
    </div>
    <div class="blog-articles-container" *ngIf="!isLoading">
        <div id="blog-articles">
            <div class="blog-article no-touch" *ngFor="let article of articles | slice: 0: articlesExtended" [ngStyle]="{ 'background': categoryColor}">
                <div [routerLink]="['/article', article.id]" class="no-outline">
                    <p class="blog-article-category">{{ this.categoryMap[article.category] }}</p>
                    <p class="blog-article-title">{{ article.title }}</p>
                    <div class="blog-article-action">MEGTEKINTÉS
                        <img src="./assets/images/icons/right-arrow2.png" class="blog-article-action-icon" alt="->">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loading-parent" *ngIf="isLoading">
        <img src="./assets/images/artwork/load.gif" class="loading" alt="Betöltés">
    </div>
</div>
