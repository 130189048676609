import {Component, OnInit, OnDestroy} from '@angular/core';
import {Article} from 'src/app/models/article.model';
import {Subscription} from 'rxjs';
import {ArticleService} from 'src/app/services/article.service';
import {AuthService} from 'src/app/auth/auth.service';
import {CategoryService} from 'src/app/services/category.service';

@Component({
    selector: 'app-expertise-articles',
    templateUrl: './expertise-articles.component.html',
    styleUrls: ['./expertise-articles.component.scss']
})
export class ExpertiseArticlesComponent implements OnInit, OnDestroy {
    articles: Article[] = [];
    isLoading = false;
    totalArticles = 0;
    userIsAuthenticated = false;
    userId: string;
    private articlesSub: Subscription;
    category = 0;
    articlesExtended = 4;
    categoryMap = [
        'TERVEZÉS',
        'KIVITELEZÉS',
        'MŰSZAKI ELLENŐRZÉS',
        'ENERGETIKAI TANÚSÍTVÁNY',
        'FÖLDMUNKÁK'
    ];

    categoryTitle: string;
    categoryDescription: string;
    categoryColor: string;

    constructor(
        public articlesService: ArticleService,
        private authService: AuthService,
        private categoryService: CategoryService
    ) {
    }

    ngOnInit() {
        this.isLoading = true;
        this.articlesService.getArticles(this.category);
        this.articlesSub = this.articlesService
            .getArticleUpdateListener()
            .subscribe((articleData: { articles: Article[] }) => {
                this.isLoading = false;
                this.articles = articleData.articles;
            });
        this.categoryService.currentCategory.subscribe(message => {
            this.categoryTitle = this.categoryService.getCategoryTitle(+message);
            this.categoryDescription = this.categoryService.getCategoryDescription(+message);
            this.categoryColor = this.categoryService.getCategoryColor(+message);
            this.isLoading = true;
        });
        this.categoryColor = 'linear-gradient(0deg, rgba(163, 132, 177, 1), rgba(186, 155, 200, 1)';
    }

    toggleExpandArticles(): void {
        this.articlesExtended = this.articlesExtended === 4 ? 100 : 4;
    }

    openArticle(id: string): void {
        document.getElementById('expertise').scrollIntoView();
    }

    ngOnDestroy() {
        this.articlesSub.unsubscribe();
    }
}

