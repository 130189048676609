import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CategoryService {

    private messageSource = new BehaviorSubject('0');
    currentCategory = this.messageSource.asObservable();

    constructor() {
    }

    changeCategory(message: string): void {
        this.messageSource.next(message);
    }

    getCategoryTitle(category: number): string {
        switch (category) {
            case 0:
                return 'Tervezés';
            case 1:
                return 'Kivitelezés';
            case 2:
                return 'Műszaki ellenőrzés';
            case 3:
                return 'Energetikai tanúsítvány';
            case 4:
                return 'Földmunkák';
            default:
                break;
        }
    }

    getCategoryDescription(category: number): string {
        switch (category) {
            case 0:
                return `<p>Ahogyan minden ember, úgy minden telek is egyedi, ezért nem hiszünk abban, hogy kétszer ugyanazt
a tervet fel lehet használni. Az Ön által elképzelt épület nem típusterv révén, hanem az Ön személyes
elképzelései alapján kerül megvalósításra. Véleményünk szerint a gondosan megtervezett épület
többszörösen is megtérül: használhatóságban, megjelenésben, gazdaságos kivitelezhetőségben és
üzemeltethetőségben.</p><br>
<p>A legfőbb célunk, hogy a megbízóink elképzeléseire alapozva, korunk követelményeinek megfelelő,
költséghatékony és fenntartható épületeket tervezzünk.</p><br>
<p>Az épületek megtervezése vagy áttervezése a legelső lépése egy építési beruházásnak. Egy új
épületnél, legyen az családi ház, középület, szálloda vagy ipari épület, nagyon fontos már az elején az
építési telek megválasztása, hiszen az épületnek együtt kell majd élnie a telek adottságaival. Ezért
érdemes már a telek kiválasztása előtt felkeresni bennünket, hogy segíthessünk megtalálni az ideális
ingatlant. A tervezés során az Ön elképzelése lesz a legfőbb szempont, de szakmai tanácsainkkal
próbáljuk elősegíteni azt, hogy az épület gazdaságosan üzemeltethető, a mindennapi használatban
kényelmes, átlátható, és esztétikailag is hosszú életű legyen.</p><br>
<p>Irodánk a családi házak és lakóépületek tervezése mellett, középületek, ipari és mezőgazdasági
épületek, szállodák és irodaházak tervezésével is foglalkozik, melyek során természetesen szintén
figyelembe vesszük a megrendelő igényeit, miközben az épület tervezése során kiemelt figyelmet
fordítunk a hatósági szabályokra is.</p>`;
            case 2:
                return `<ul><li class="reference">Szarvas, Közbiztonsági Centrum energetikai korszerűsítésének építész műszaki ellenőrzése</li>
<li class="reference">„Bölcsődefejlesztési Program Szegeden – Siha Közi Bölcsőde korszerűsítése” építész műszaki ellenőrzése</li>
<li class="reference">Szarvas Lengyel palota épületének hőszigetelési munkáival kapcsolatos műszaki ellenőrzés</li>
<li class="reference">Szeged, Vörösmarty Mihály Ált. iskola Energetikai korszerűsítésének építész műszaki ellenőrzése</li>
<li class="reference">Szarvasi Komép Városgazdálkodási Nkft. „Szarvas Város önkormányzati tulajdonú épületeinek fotovillamos rendszerrel történő fejlesztése 2” projekt építész műszaki ellenőrzése</li>
<li class="reference">Szarvas „Aprók kertje bölcsőde felújítása műszaki ellenőrzése</li>
<li class="reference">"Esély otthon pályázat keretében: meglévő lakások felújítása” projekt műszaki ellenőrzése, 5540 Szarvas, Szentesi út Hrsz.: 4592/3.</li>
<li class="reference">„Szarvas Tessedik Sámuel Múzeum látogatóbarát és interaktív fejlesztése térség szellemi örökségen alapú fenntartható vonzerőinek bővítése érdekében” projekt műszaki ellenőrzése</li>
<li class="reference">„Háziorvosi rendelők infrastrukturális fejlesztése Szarvason” Szarvas, Széchenyi utca 2. sz, műszaki ellenőrzése, </li>
<li class="reference">Trágyatároló építésének műszaki ellenőrzése, Székkutas külterület, Tanya HRSZ:085/40</li>
<li class="reference">Családi ház építésnek műszaki ellenőrzése Orosháza, Damjanich utca 11. sz.</li>
<li class="reference">G-Bau 2000 Kft telephely építésének műszaki ellenőrzése, Mórahalom, 2176/3</li>
<li class="reference">Üdülő épület építésének műszaki ellenőrzése, Orosháza Hűvös köz, hrsz:7795</li>
<li class="reference">Nyomdaipari csarnok építésének műszaki ellenőrzése Mórahalom</li>
<li class="reference">Papír feldolgozó csarnok építésének műszaki ellenőrzése, Mórahalom Papírgyári út, hrsz:1914/18</li>
<li class="reference">Családi ház építésének műszaki ellenőrzés, Szeged, Reizner J. utca 35. sz.</li></ul><br>`;
            case 3:
                return `<p>Az ingatlan eladása vagy épp energetikai megújítása során mindig szükség van az épület energetikai
tanúsítványára, mellyel pontos kép kapható arról, milyen munkálatok szükségesek ahhoz, hogy az
ingatlan még takarékosabb energiafelhasználást tudjon elérni.</p><br>
<p>Szakmai tudásunknak köszönhetően hivatalos energiatanúsítványt tudunk kiállítani ingatlana
számára, mellyel egyrészt értékesíteni tudja azt, másrészt pontos képet kaphat arról, milyen
beruházások szükségesek ahhoz, hogy az épület energiapazarlása csökkenjen, és ezzel az Ön
megtakarításai nőjenek.</p><br>`;
            case 4:
                return `<p>Vállalkozásunk rendelkezik egy Venieri 1.33 B típusú Perkins motoros, traktor alapú, gumikerekes kotró-rakodó földmunkagéppel, mely 4 t tömegű, mindössze 1,40 m széles és 2,27 m magas, 80 cm sugarú körben megfordul, így kis udvarokba, szűk helyekre is befér. 2,70 m mélységig ás és 3,00 magasságig emel, maximum 1000 kg-ot. A géphez rendelkezünk 30 cm-es, 40 cs-es, 50 cm-es ásó kanállal, 1,25 m széles iszapoló kanállal, elöl nyitható körmös kanállal.</p><br>
<p>Földmunkagépünk adottságai nagyban megkönnyíti és felgyorsítja a kivitelezési munkáinkat, továbbá gépkezelőnkkel bérmunkát is vállalunk, úgymint alapárok ásást, tuskózást, tükör készítést, törmelék és földbehordást, egyéb rakodási munkákat. Kérjük keressenek minket bizalommal, ha szüksége lenne földmunkagépi szolgáltatásunkra.</p><br>`;
            default:
                return '';
        }
    }

    getCategoryColor(category: number): string {
        switch (category) {
            case 0:
                return 'linear-gradient(0deg, rgba(163, 132, 177, 1), rgba(186, 155, 200, 1)';
            case 1:
                return 'linear-gradient(0deg, rgba(187, 139, 139, 1), rgba(215, 168, 168, 1)';
            case 2:
                return 'linear-gradient(0deg, rgba(150, 158, 185, 1), rgba(176, 183, 210, 1)';
            case 3:
                return 'linear-gradient(0deg, rgba(157, 181, 184, 1), rgba(183, 206, 210, 1)';
            case 4:
                return 'linear-gradient(0deg, rgba(175, 169, 148, 1), rgba(201, 195, 174, 1)';
            default:
                return 'linear-gradient(0deg, rgba(163, 132, 177, 1), rgba(186, 155, 200, 1)';
        }
    }
}
