import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {GalleryItem} from '../models/gallery-item.model';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class GalleryService {
    private galleries: GalleryItem[] = [];
    private galleriesUpdated = new Subject<{ galleries: GalleryItem[] }>();
    lastRouteBS = new BehaviorSubject('index');

    private messageSource = new BehaviorSubject(null);
    gallerySelection = this.messageSource.asObservable();

    constructor(private http: HttpClient) {
    }

    changeGallerySelection(galleryItem): void {
        this.messageSource.next(galleryItem);
    }

    getGalleryItems(category: number) {
        const queryParams = `?category=${category}`;
        this.http.get<GalleryItem[]>(environment.backendUrl + 'gallery/' + queryParams)
            .subscribe(transformedGalleryData => {
                this.galleries = transformedGalleryData;
                this.galleriesUpdated.next({
                    galleries: [...this.galleries]
                });
            });
    }

    getGalleryUpdateListener() {
        return this.galleriesUpdated.asObservable();
    }

    saveGalleryItem(galleryItem: GalleryItem) {
        return this.http.post(environment.backendUrl + 'gallery', galleryItem);
    }

    deleteGalleryItemById(id: string) {
        return this.http.delete(environment.backendUrl + 'gallery?_id=' + id);
    }

    uploadImage(file: any): Observable<{ src: string }> {
        const formData = new FormData();
        formData.set('src', file);
        return this.http.post<{ src: string }>(environment.backendUrl + 'gallery/image', formData);
    }

    getGalleryItemById(id: string): Observable<GalleryItem> {
        return this.http.get<GalleryItem>(environment.backendUrl + 'gallery?_id=' + id);
    }
}
