import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {GalleryItem} from 'src/app/models/gallery-item.model';
import {Subscription} from 'rxjs';
import {GalleryService} from 'src/app/services/gallery.service';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

    constructor(
        private galleryService: GalleryService
    ) {
    }

    selectedCategory = 0;
    lineOffset = 0;
    categories = [];
    isLoading = false;

    @ViewChild('selected_parent') selected_parent: ElementRef;
    @ViewChild('selected') selected: HTMLElement;
    @ViewChild('category0') category0: ElementRef;
    @ViewChild('category1') category1: ElementRef;
    @ViewChild('category2') category2: ElementRef;

    galleryItems: GalleryItem[] = [];
    extendedView = 8;

    private gallerySub: Subscription;

    ngOnInit(): void {
        this.isLoading = true;
        this.galleryService.getGalleryItems(this.selectedCategory);
        this.gallerySub = this.galleryService
            .getGalleryUpdateListener()
            .subscribe((galleriesData: { galleries: GalleryItem[] }) => {
                this.galleryItems = galleriesData.galleries;
                this.isLoading = false;
            });
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit(): void {
        this.categories = [
            this.category0,
            this.category1,
            this.category2
        ];
    }

    clickedOnGalleryCategory(category: number): void {
        this.isLoading = true;
        this.galleryItems = [];
        this.selectedCategory = category;
        this.galleryService.getGalleryItems(category);
        this.lineOffset = this.categories[category].nativeElement.offsetLeft - this.selected_parent.nativeElement.offsetLeft;
        this.extendedView = 8;
    }

    openGallery(galleryItem: any): void {
        this.galleryService.changeGallerySelection(galleryItem);
    }

    toggleExtendView(): void {
        if (this.extendedView == 8) {
            this.extendedView = 100;
        } else {
            this.extendedView = 8;
        }
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy() {
        this.gallerySub.unsubscribe();
    }

}
