<div id="gallery-fullscreen" #gallery [ngStyle]="{'display': (isGalleryOpen) ? 'block' : 'none'}">
    <div id="container">
        <div class="selected"
             [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/gallery/' + selectedItem?.src + ')'}">
            <div class="fill"></div>
        </div>
        <div id="gradient"></div>
        <img class="close-icon" (click)="closeGallery();" src="./assets/images/icons/close.png" alt="Bezárás">
        <div class="details">
            <p class="date">{{ selectedItem?.added | date: 'yyyy. MM. dd.' }}</p>
            <p class="description" #scroll>{{ selectedItem?.description }}</p>
            <div id="image-queue-container" #imagequeue>
                <div *ngFor="let galleryItem of galleryItems; let index = index">
                    <div class="gallery-image" (click)="selectGalleryItem(galleryItem)"
                         [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/gallery/' + galleryItem?.src + ')', 'filter': (index === selected % galleryItems.length) ? 'brightness(1)' : 'brightness(40%)'}"></div>
                </div>
            </div>
        </div>
    </div>
</div>
