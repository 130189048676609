import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Article } from '../models/article.model';

const BACKEND_URL = 'https://oros-haz.hu:3000/api/send';

@Injectable({ providedIn: 'root' })
export class MailService {
  timeOut = 0;
  private messageSource = new BehaviorSubject(-1);
  messageStatus = this.messageSource.asObservable();

  constructor(private http: HttpClient, private router: Router) {}

  sendMail(name: string, email: string, message: string) {
    const mailData = new FormData();
    mailData.append('name', name);
    mailData.append('email', email);
    mailData.append('message', message);
    this.http
      .post<{ message: string }>(
        BACKEND_URL,
        mailData
      )
      .subscribe(responseData => {
        //console.log('Üzenet küldése folyamatban...');
        if (responseData.message === 'Sikeres elküldés!') { // todo ez legyen a response
          this.changeMessageStatus(1);
        } else { this.changeMessageStatus(0); }
      });
  }

  changeMessageStatus(value: number): void {
    this.messageSource.next(value);
  }

}
