import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

import {AuthData} from './auth-data.model';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class AuthService {
    private isAuthenticated = false;
    private tokenTimer: any;
    private authStatusListener = new Subject<boolean>();

    constructor(private http: HttpClient, private router: Router) {
    }

    getAuthStatusListener() {
        return this.authStatusListener.asObservable();
    }

    login(email: string, password: string) {
        const authData: AuthData = {username: email, password};
        this.http
            .post<{ token: string; expiresIn: number; userId: string }>(
                environment.backendUrl + 'login',
                authData
            )
            .subscribe(response => {
                if (response.token) {
                    const expiresInDuration = response.expiresIn;
                    this.setAuthTimer(expiresInDuration);
                    this.isAuthenticated = true;
                    this.authStatusListener.next(true);
                    localStorage.setItem('token', response.token);
                    this.router.navigateByUrl('/auth/editor');
                }
            }, error => {
                this.authStatusListener.next(false);
            });
    }

    autoAuthUser() {
        const token = this.getAuthData();
        if (!token) {
            return;
        }

        this.http.get(environment.backendUrl + 'verify').subscribe((resp) => {
            // console.log(resp);
            this.router.navigateByUrl('/auth/editor');
            this.isAuthenticated = true;
            this.authStatusListener.next(true);
        }, error => {
            // console.log(error);
            this.clearAuthData();
            this.isAuthenticated = false;
            this.authStatusListener.next(false);
        });
    }

    logout() {
        this.isAuthenticated = false;
        this.authStatusListener.next(false);
        clearTimeout(this.tokenTimer);
        this.clearAuthData();
        this.router.navigate(['/']);
    }

    private setAuthTimer(duration: number) {
        this.tokenTimer = setTimeout(() => {
            this.logout();
        }, duration);
    }

    private clearAuthData() {
        localStorage.clear();
    }

    private getAuthData() {
        return localStorage.getItem('token');
    }

    isAuth() {
        return Boolean(localStorage.getItem('token'));
    }

    delete() {
        this.clearAuthData();
    }

    getToken() {
        return localStorage.getItem('token');
    }
}
