import {Component, OnInit, Renderer2, HostListener, ViewChild, ElementRef} from '@angular/core';
import {GalleryService} from 'src/app/services/gallery.service';
import {GalleryItem} from 'src/app/models/gallery-item.model';
import {Subscription} from 'rxjs';

export enum KEY_CODE {
    RIGHT_ARROW = 39,
    LEFT_ARROW = 37,
    ESC = 27
}

@Component({
    selector: 'app-gallery-fullscreen',
    templateUrl: './gallery-fullscreen.component.html',
    styleUrls: ['./gallery-fullscreen.component.scss']
})

export class GalleryFullscreenComponent implements OnInit {

    private gallerySub: Subscription;

    galleryItems: GalleryItem[] = [];
    selectedCategory = 0;
    selected = 0;
    isGalleryOpen = false;
    shiftedSoFar = 0;
    selectedItem: GalleryItem = null;

    @ViewChild('gallery') gallery: ElementRef;
    @ViewChild('imagequeue', {read: ElementRef}) public imageQueue: ElementRef<any>;
    @ViewChild('scroll', {read: ElementRef}) public scroll: ElementRef<any>;

    constructor(
        private renderer: Renderer2,
        private galleryService: GalleryService) {
    }

    ngOnInit(): void {
        this.isGalleryOpen = false;
        this.gallerySub = this.galleryService
            .getGalleryUpdateListener()
            .subscribe((galleriesData: { galleries: GalleryItem[] }) => {
                this.galleryItems = galleriesData.galleries;
            });
        this.galleryService.gallerySelection
            .subscribe(galleryItem => {
                if (galleryItem) {
                    this.isGalleryOpen = true;
                    this.selectedItem = galleryItem;
                    this.galleryItems.find((value, index) => {
                        if (value._id === this.selectedItem._id) {
                            this.selected = index;
                        }
                    });
                    this.renderer.addClass(document.body, 'noscroll');
                    setTimeout(() => {
                        this.scrollToSelected();
                    }, 200);
                }
            });
    }

    @HostListener('window:keyup', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (this.isGalleryOpen) {
            if (event.key === 'ArrowRight' && this.selected < this.galleryItems.length - 1) {
                this.selected++;
            }

            if (event.key === 'ArrowLeft' && this.selected > 0) {
                this.selected--;
            }

            this.galleryService.changeGallerySelection(this.galleryItems[this.selected]);
            this.scrollToSelected();
        }

        if (event.keyCode === KEY_CODE.ESC) {
            this.closeGallery();
        }
    }

    scrollToSelected() {
        this.imageQueue.nativeElement.scrollLeft = 210 * (this.selected % this.galleryItems.length);
    }

    closeGallery(): void {
        this.renderer.removeClass(document.body, 'noscroll');
        this.isGalleryOpen = false;
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngOnDestroy() {
        this.isGalleryOpen = false;
        this.galleryItems = [];
        this.gallerySub.unsubscribe();
    }

    selectGalleryItem(galleryItem: GalleryItem) {
        this.galleryService.changeGallerySelection(galleryItem);
    }
}
