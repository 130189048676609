<div class="header">
    <input type="checkbox" id="chk" [(ngModel)]="checkBoxValue">
    <label for="chk" class="show-menu-btn">
        <img src="./assets/images/icons/fa-bars.png" id="hamburger">
    </label>

    <ul class="menu">
        <!--img class="logo" src="https://via.placeholder.com/50"-->
        <a id="menu-main" class="link" [ngxScrollTo]="'#main'" (click)="closeResponsiveMenu()">Kezdőlap</a>
        <a class="link" [ngxScrollTo]="'#expertise'" (click)="closeResponsiveMenu()">Szakterületek</a>
        <a class="link" [ngxScrollTo]="'#gallery'" (click)="closeResponsiveMenu()">Galéria</a>
        <a class="link" [ngxScrollTo]="'#about'" (click)="closeResponsiveMenu()">Rólunk</a>
        <a class="link" [ngxScrollTo]="'#contact'" (click)="closeResponsiveMenu()">Kapcsolat</a>
        <label for="chk" class="hide-menu-btn">
            <img src="./assets/images/icons/close.png" id="hamburger" alt="Bezárás">
        </label>
    </ul>
</div>
