<div id="footer" class="section">
    <div class="container">
        <h1 class="footer-title">Oros-Ház Kft.</h1>
        <div class="footer-column-container">
            <div class="footer-column">
                <span>Munkatársaink:</span>
                <p>Ferenczi Tünde építészmérnök</p>
                <p>Pintér Virág építészmérnök</p>
                <br/>
                <br/>
                <span>Partnereink:</span>
                <p>EMATOM Kft.</p>
                <p>Nagy Tamás építészmérnök</p>
                <p>1037 Budapest Bécsi út 85.</p>
                <br/>
                <p>T11MÉDIA Kft</p>
                <p>Takács Gábor</p>
                <a href="http://epiteszetilatvanytervezes.hu">epiteszetilatvanytervezes.hu</a>
            </div>
            <div class="footer-column">
                <span>Nyitvatartás:</span>
                <p>Hétfő: 08:00-17:00<br>Kedd: 08:00-17:00<br>Szerda: 08:00-17:00<br>Csütörtök: 08:00-17:00<br>Péntek:
                    08:00-17:00<br>Szombat: ZÁRVA<br>Vasárnap: ZÁRVA</p>
            </div>
            <div class="footer-column">
                <span>Elérhetőségek:</span>
                <p id="name">Bartók Csilla</p>
                <p id="tel">06 30 869 6103</p>
                <p id="email">csilla.bartok@gmail.com</p>
                <br/>
                <p>Bartók Lászlóné</p>
                <p>06 30 498 3179</p>
            </div>
            <div class="footer-column">
                <span>Céginformáció:</span>
                <p id="corp_name">Oros-Ház Kft.</p>
                <span>Cím:</span>
                <p id="address">5900 Orosháza, Szőlő krt. 31.</p>
                <span>Adószám:</span>
                <p>22704922204</p>
                <span>Cégjegyzékszám:</span>
                <p id="reg_num">04 09 009965</p>
            </div>
        </div>
        <button class="standard-button editor" [routerLink]="['/auth/login']">Szerkesztő belépés</button>
        <p class="footer-dotspace-title">A weboldalt készítette:</p>
        <a href="https://dotspace.hu" target="_blank">
            <div class="footer-dotspace-logo-container">
                <img class="footer-dotspace-logo" src="./assets/images/icons/footer/dotspace.png">
                <img class="footer-dotspace-logo footer-dotspace-logo-white" src="./assets/images/icons/footer/dotspace-white.png">
            </div>
        </a>
        <div class="footer-legal-container">
            <span class="footer-legal">Jogvédett &copy;<script>document.write(new Date().getFullYear());</script></span>
            <!-- <span class="footer-legal"><a href="#" class="link">Szerződési feltételek</a></span>
            <span class="footer-legal"><a href="#" class="link">Adatkezelési nyilatkozat</a></span>
            <span class="footer-legal"><a href="#" class="link">Jogi nyilatkozat</a></span> -->
            <span class="footer-legal">Ikonok: <a href="#" class="link">Pexels</a>, <a href="#" class="link">Freepik</a></span>
        </div>
    </div>
</div>
