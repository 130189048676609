import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Slide} from '../models/slide.model';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class ImageService {
    private slides: Slide[] = [];
    private slidesUpdated = new Subject<{ slides: Slide[] }>();

    constructor(private http: HttpClient, private router: Router) {
    }

    getSlides() {
        this.http
            .get<{ message: string; slides: any }>(
                environment.backendUrl + 'slides'
            )
            .pipe(
                map(slideData => {
                    // console.log(slideData.message);
                    return {
                        slides: slideData.slides.map(slide => {
                            return {
                                _id: slide._id,
                                path: slide.src
                            };
                        })
                    };
                })
            )
            .subscribe(transformedSlideData => {
                this.slides = transformedSlideData.slides;
                this.slidesUpdated.next({
                    slides: [...this.slides]
                });
            });
    }

    getSlideUpdateListener() {
        return this.slidesUpdated.asObservable();
    }

    updateSlide(slide: Slide) {
        this.http.put(environment.backendUrl + 'slide', slide).subscribe();
    }

    deleteById(id: string) {
        return this.http.delete(environment.backendUrl + 'slide?_id=' + id);
    }

    saveSlide(file: any) {
        const formData = new FormData();
        formData.append('slide', file);
        return this.http.post(environment.backendUrl + 'slide', formData);
    }
}
