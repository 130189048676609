import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IndexComponent} from './components/index/index/index.component';
import {ArticleComponent} from './components/index/article/article.component';
import {NotFoundComponent} from './components/not-found/not-found.component';

const routes: Routes = [
    {path: '', component: IndexComponent},
    {path: '404', component: NotFoundComponent},
    {path: 'article/:id', component: ArticleComponent},
    {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
    {path: '**', redirectTo: '/'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

