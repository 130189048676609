import {Component, OnInit, ViewChild, ElementRef, Injectable} from '@angular/core';
import {ArticleService} from 'src/app/services/article.service';
import {CategoryService} from 'src/app/services/category.service';

@Component({
    selector: 'app-expertise',
    templateUrl: './expertise.component.html',
    styleUrls: ['./expertise.component.scss']
})

@Injectable()
export class ExpertiseComponent implements OnInit {

    constructor(
        private articleService: ArticleService,
        private categoryService: CategoryService
    ) {
    }

    @ViewChild('underline_parent') underline_parent: ElementRef;
    @ViewChild('underline') underline: HTMLElement;
    @ViewChild('category0') category0: ElementRef;
    @ViewChild('category1') category1: ElementRef;
    @ViewChild('category2') category2: ElementRef;
    @ViewChild('category3') category3: ElementRef;
    @ViewChild('category4') category4: ElementRef;

    selected = 0;
    lineOffset = 0;
    lineWidth = 87;
    categories = [];

    ngOnInit(): void {
        //this.categoryService.currentCategory.subscribe(message => this.message = message)
    }

    // tslint:disable-next-line:use-lifecycle-interface
    ngAfterViewInit(): void {
        this.categories = [
            this.category0,
            this.category1,
            this.category2,
            this.category3,
            this.category4,
        ];
    }

    selectCategory(category: number): void {
        this.categoryService.changeCategory(String(category));
        this.articleService.getArticles(category);
        this.selected = category;
        this.lineWidth = this.categories[category].nativeElement.offsetWidth;
        this.lineOffset = this.categories[category].nativeElement.offsetLeft - this.underline_parent.nativeElement.offsetLeft;
    }

}
