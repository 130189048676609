<div id="contact" class="section">
    <div class="container">
        <div class="contact-text">
            <h1 class="section-title">Kapcsolat</h1>
            <p class="section-description">Amennyiben felkeltettük az érdeklődését, keressen minket bizalommal a lenti
                elérhetőségek valamelyikén, vagy írjon nekünk üzenetet, és amint tudunk válaszolunk Önnek.</p>
        </div>
        <div class="contact-content">
            <!-- <div class="contact-form-container">
                <h2 class="contact-form-title">Lépjen velünk kapcsolatba!</h2>
                <form [formGroup]="form" (submit)="onSavePost()" class="contact-form">
                    <input type="text" formControlName="name" name="name" placeholder="Neve" maxlength="40">
                    <input type="email" formControlName="address" name="email" placeholder="E-mail címe" maxlength="40">
                    <textarea class="contact-form-message" formControlName="body" placeholder="Üzenet" name="message"
                              maxlength="1000"></textarea>
                    <div class="contact-submit-container">
                        <button type="submit" class="standard-button contact-submit">
                            <div class="button-parent"><img src="/assets/images/artwork/loading-white.gif"
                                                            class="loading" *ngIf="isLoading"> Elküldés
                            </div>
                        </button>
                    </div>
                </form>
            </div> -->
            <div class="contact-card-container">
                <div class="contact-card">
                    <div class="contact-card-content">
                        <h2 class="contact-card-title">Elérhetőségeink</h2>
                        <div class="contact-card-body">
                            <h4>Tervezés, kivitelezés, műszaki ellenőrzés</h4>
                            <h4>Bartók Csilla</h4>
                            <div class="contact-card-item">
                                <img class="contact-card-item-icon" src="./assets/images/icons/contact/map.png"><span
                                class="link"
                                (click)="mapsSelector()">5900 Orosháza, Tass utca 7. fszt. 1. sz.</span>
                            </div>
                            <div class="contact-card-item">
                                <img class="contact-card-item-icon" src="./assets/images/icons/contact/email.png"><a
                                href="mailto:csilla.bartok@gmail.com"><span class="link">csilla.bartok@gmail.com</span></a>
                            </div>
                            <div class="contact-card-item">
                                <img class="contact-card-item-icon" src="./assets/images/icons/contact/phone.png"><a
                                href="tel:+36 30 869 6103"><span class="link">+36 30 869 6103</span></a>
                            </div>
                        </div>
                        <div class="contact-card-body">
                            <h4>Földmunkák</h4><h4>Farkas Zoltán</h4>
                            <div class="contact-card-item">
                                <img class="contact-card-item-icon" src="./assets/images/icons/contact/phone.png"><a
                                href="tel:+36 30 617 8248"><span class="link">+36 30 617 8248</span></a>
                            </div>
                        </div>

                        <div class="contact-card-facebook-container-parent">
                            <a class="contact-card-facebook-container" href="https://www.facebook.com/Bartokep">
                                <img class="contact-card-facebook-icon"
                                     src="./assets/images/icons/contact/facebook.png">oroshazkft
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-popup (click)="cancelOption()" class="my-prompt" [isOpen]="popUpOpen">
            <div class="box">
                <div class="popup-header">
                    <p class="popup-title">Sikeres üzenetküldés!</p>
                    <img src="./assets/images/icons/close.png" (click)="cancelOption()" class="close-popup"
                         alt="Bezárás">
                </div>
                <div class="popup-body">
                    Köszönjük az érdeklődést, hamarosan felvesszük önnel a kapcsolatot.
                </div>
                <footer>
                    <button class="popup-button">RENDBEN</button>
                </footer>
            </div>
        </app-popup>
    </div>
</div>
