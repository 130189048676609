import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor() { }

  @ViewChild('chk') checkBox: HTMLElement;
  checkBoxValue = false;

  ngOnInit(): void {
  }

  openResponsiveMenu() {
    this.checkBoxValue = true;
  }

  closeResponsiveMenu() {
    this.checkBoxValue = false;
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }

}
