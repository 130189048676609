<div id="article">
    <div class="loading-parent" *ngIf="isLoading === 1">
        <img src="./assets/images/artwork/load.gif" class="loading">
    </div>
    <div *ngIf="isLoading === -1" class="loading-error-parent">
        <div class="text-and-button">
            <img src="./assets/images/artwork/sad.gif" class="gif">
            <h3>{{ errorMessage }}</h3>
            <p *ngIf="offline">Amint csatlakozol, újratöltjük neked az oldalt.</p>
            <button class="standard-button" *ngIf="!offline" (click)="offline ? reloadPage() : backToMainPage()">{{ offline ? "Újratöltés" : "Vissza a főoldalra" }}</button>
        </div>
    </div>
    <div id="article-container" *ngIf="isLoading === 0">
        <h1 id="article-title" class="article-title">{{ article.title }}</h1>
        <p id="article-created" class="article-description">{{ article.date | date: 'yyyy. MM. dd.' }}</p>
        <div id="article-content" *ngFor="let block of article.content['blocks']">
            <h1 class="article-title" *ngIf="block.type === 'header'" [innerHTML]="block.data.text"></h1>
            <div class="article-p dark-mode-ready" *ngIf="block.type === 'paragraph'" [innerHTML]="block.data.text"></div>
            <img class="article-image dark-mode-ready" *ngIf="block.type === 'image'" [src]="block.data.file.url"/>
            <ul *ngIf="block.type === 'list'">
                <li *ngFor="let item of block.data.items" [innerHTML]="item"></li>
            </ul>
        </div>
    </div>
    <div id="article-controls">
        <img id="article-dark-mode" class="article-controls-icon" src="./assets/images/icons/dark-mode.png">
        <img id="article-close" class="article-controls-icon" src="./assets/images/icons/close.png">
    </div>
</div>
