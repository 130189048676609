<div id="gallery" class="section" onload="loadGalleryContent(0)">
    <div class="container" id="image-selector">
        <div class="gallery-text">
            <h1 id="deleteme" class="section-title">Galéria</h1>
            <p class="section-description">A galériában megtalálhatja az egyes szakterületeink által végzett
                legfontosabb munkákról készített képeket 1986-tól napjainkig.</p>
        </div>
        <div class="gallery-selector-container-parent">
            <div id="gallery-selector-selected-container" #selected_parent>
                <div id="gallery-selector-selected" [ngStyle]="{'left': lineOffset + 'px'}" #selected></div>
            </div>
            <div id="gallery-selector-container">
                <div id="gallery-cat-1" class="gallery-selector-label-container active"
                     (click)="clickedOnGalleryCategory(0)">
                    <p class="gallery-selector-label" #category0
                       [ngStyle]="{'color': (selectedCategory === 0) ? '#ffffff' : '#757575'}">TERVEZÉS</p></div>
                <div id="gallery-cat-2" class="gallery-selector-label-container" (click)="clickedOnGalleryCategory(1)">
                    <p class="gallery-selector-label" #category1
                       [ngStyle]="{'color': (selectedCategory === 1) ? '#ffffff' : '#757575'}">KIVITELEZÉS</p></div>
                <div id="gallery-cat-3" class="gallery-selector-label-container" (click)="clickedOnGalleryCategory(2)">
                    <p class="gallery-selector-label" #category2
                       [ngStyle]="{'color': (selectedCategory === 2) ? '#ffffff' : '#757575'}">FÖLDMUNKÁK</p></div>
            </div>
        </div>
        <div class="loading-parent" *ngIf="isLoading === true">
            <img src="./assets/images/artwork/load.gif" class="loading" alt="Betöltés...">
        </div>
        <div class="x-grid" *ngIf="isLoading === false">
            <p *ngIf="galleryItems.length === 0 && !isLoading" class="gallery-not-found">Nem található kép ebben a
                kategóriában.</p>
            <div *ngFor="let galleryItem of galleryItems | slice: 0: extendedView; let index = index">
                <div class="card" (click)="openGallery(galleryItem)">
                    <div class="gallery-image"
                         [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/gallery/' + galleryItem?.src + ')'}"></div>
                    <div class="gallery-description">
                        <p class="gallery-description-label">{{ (galleryItem?.description.length > 130) ? (galleryItem?.description | slice: 0:130) + '...' : (galleryItem?.description) }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="gallery-show-more" *ngIf="galleryItems.length > 8">
            <button class="standard-button"
                    (click)="toggleExtendView()">{{ extendedView == 8 ? "Összes megtektintése" : "Kevesebb megtekintése" }}</button>
        </div>
    </div>
</div>
