import {Component, OnDestroy, OnInit} from '@angular/core';
import {Article} from 'src/app/models/article.model';
import {Subscription} from 'rxjs';
import {ArticleService} from 'src/app/services/article.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-article',
    templateUrl: './article.component.html',
    styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit, OnDestroy {

    article: Article;
    isLoading = 0;
    private articlesSub: Subscription;
    categoryMap = [
        'TERVEZÉS',
        'KIVITELEZÉS',
        'MŰSZAKI ELLENŐRZÉS',
        'ENERGETIKAI TANÚSÍTVÁNY',
        'FÖLDMUNKÁK'
    ];
    timer;
    timeLeft: number = 5;
    errorMessage = 'Hiba történt a cikk betöltésénél.';
    offline = false;

    constructor(
        public articleService: ArticleService,
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.isLoading = 1;
        this.startTimeout();
        this.articleService.changeLastRoute('article');
        this.articlesSub = this.articleService
            .getArticle(this.route.snapshot.paramMap.get('id'))
            .subscribe(article => {
                clearInterval(this.timer);
                //this.timer = setInterval(null, 1000);
                //console.log(navigator.onLine);
                this.titleService.setTitle(article.title + ' | Oros-Ház Kft.');
                if (article) {
                    this.article = article;
                    this.article.content = JSON.parse(article.content);
                    this.isLoading = 0;
                    //console.log(this.article["article"].title);
                } else {
                    this.isLoading = -1;
                    this.errorMessage = 'Nem találtunk ilyen cikket.';
                }
            });

        window.addEventListener('online', () => {
            window.location.reload();
        });

        window.addEventListener('offline', () => {
            this.offline = true;
        });
    }

    startTimeout() {
        this.timer = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.isLoading = -1;
                clearInterval(this.timer);
                if (!navigator.onLine) {
                    this.offline = true;
                    this.errorMessage = 'Úgy tűnik, nincs internetkapcsolatod.';
                }
            }
        }, 1000);
    }

    reloadPage(): void {
        window.location.reload();
    }

    backToMainPage(): void {
        this.router.navigate(['/']);
    }

    ngOnDestroy() {
        this.articlesSub.unsubscribe();
    }
}
