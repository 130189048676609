<div id="main">
    <div id="title-bar">
        <img src="assets/images/artwork/logo.svg">
        <div>
            <h1 class="main-title">Oros-Ház Kft.</h1>
            <q class="main-description">Álmokból tervek, a tervekből valóság.</q>
        </div>
    </div>
    
    <div id="x-slideshow">
        <div #prev class="x-prev" *ngIf="slides !== null"
             [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/slide/' + slides[slides.length - 1]?.path + ')'}">
            <div class="slide-max"></div>
        </div>
        <div #current class="x-current" *ngIf="slides !== null"
             [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/slide/' + slides[0]?.path + ')'}">
            <div class="slide-max"></div>
        </div>
        <div #next class="x-next" *ngIf="slides !== null"
             [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/slide/' + slides[1]?.path + ')'}">
            <div class="slide-max"></div>
        </div>
        <div #nextfar class="x-next-far" *ngIf="slides !== null"
             [ngStyle]="{'background-image': 'url(https://oros-haz.hu/images/slide/' + slides[2]?.path + ')'}">
            <div class="slide-max"></div>
        </div>
        <div id="page-indicator-container">
            <div *ngFor="let page of slides; let index = index"
                 [ngClass]="{'page-indicator-active': index == (currentPage % slides.length), 'page-indicator-dot': true}">
            </div>
        </div>
    </div>


<!--    <a href="#expertise"><img class="scroll-gif" src="./assets/images/artwork/scroll.gif" alt="Görgess lefelé."></a>-->
    <div class="mouse"><div class="scroll-wheel"></div></div>
</div>
