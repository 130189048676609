import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './components/index/navbar/navbar.component';
import {HeaderComponent} from './components/index/header/header.component';
import {ExpertiseComponent} from './components/index/expertise/expertise.component';
import {GalleryComponent} from './components/index/gallery/gallery.component';
import {AboutComponent} from './components/index/about/about.component';
import {ContactComponent} from './components/index/contact/contact.component';
import {FooterComponent} from './components/index/footer/footer.component';
import {IndexComponent} from './components/index/index/index.component';
import {ExpertiseArticlesComponent} from './components/index/expertise-articles/expertise-articles.component';
import {ArticleComponent} from './components/index/article/article.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CategoryService} from './services/category.service';

import {SWIPER_CONFIG, SwiperConfigInterface, SwiperModule} from 'ngx-swiper-wrapper';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {GalleryFullscreenComponent} from './components/index/gallery-fullscreen/gallery-fullscreen.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {DragulaModule} from 'ng2-dragula';
import {PopupComponent} from './components/popup/popup.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {ArticleService} from './services/article.service';
import {GtagModule} from 'angular-gtag';
import {AuthInterceptor} from './auth/auth-interceptor';
import {AngularSvgIconModule} from 'angular-svg-icon';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
};

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        HeaderComponent,
        ExpertiseComponent,
        GalleryComponent,
        AboutComponent,
        ContactComponent,
        FooterComponent,
        IndexComponent,
        ExpertiseArticlesComponent,
        ArticleComponent,
        GalleryFullscreenComponent,
        NotFoundComponent,
        PopupComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SwiperModule,
        ScrollToModule.forRoot(),
        DragulaModule.forRoot(),
        DeviceDetectorModule,
        GtagModule.forRoot({trackingId: 'UA-175956574-1', trackPageviews: true}),
        AngularSvgIconModule,
    ],
    providers: [
        CategoryService,
        ArticleService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
