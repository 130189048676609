import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MailService} from 'src/app/services/mail.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    constructor(
        public mailService: MailService
    ) {
    }

    form: FormGroup;
    isLoading = false;
    timeLeft: number = 0;
    timer;
    color = '#ff3300';

    ngOnInit(): void {
        this.form = new FormGroup({
            name: new FormControl(null, {
                validators: [Validators.required, Validators.minLength(3)]
            }),
            address: new FormControl(null, {validators: [Validators.required]}),
            body: new FormControl(null, {validators: [Validators.required]})
        });
        this.mailService.messageStatus.subscribe(message => {
            switch (message) {
                case 0:
                    this.isLoading = false;
                    break;
                case 1:
                    this.openPopUp();
                    this.mailService.changeMessageStatus(-1);
                    this.form.reset();
                    this.isLoading = false;
                    this.startTimeout();
                    break;
                default:
                    break;
            }
        });

    }

    startTimeout() {
        this.timeLeft = 30;
        this.timer = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                clearInterval(this.timer);
            }
        }, 1000);
    }

    onSavePost() {
        if (this.timeLeft > 0) {
            return;
        }
        if (this.form.invalid) {
            return;
        }
        this.isLoading = true;
        this.mailService.sendMail(
            this.form.value.name,
            this.form.value.address,
            this.form.value.body
        );
    }

    mapsSelector(): void {
        window.open('https://goo.gl/maps/DuTBbRoAyhLtsBFG9');
    }

    popUpOpen = false;

    openPopUp() {
        this.popUpOpen = true;
    }

    deleteOption() {
        this.popUpOpen = false;
    }

    cancelOption() {
        this.popUpOpen = false;
    }

}
